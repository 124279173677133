/**
 * Created by henian.xu on 2019/4/8.
 *
 */

// import Stripe from 'Stripe';
let loading = false;
let timer = 0;
const _resolve = [];

function check() {
    // console.log(window.Stripe);
    if (!window.Stripe) return;
    _resolve.forEach(resolve => {
        resolve(window.Stripe);
    });
    clearInterval(timer);
    timer = 0;
    loading = false;
}

export default function loadStripe() {
    return new Promise(resolve => {
        if (!window.Stripe) {
            _resolve.push(resolve);
            if (loading) return;
            loading = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://js.stripe.com/v3/';
            document.body.appendChild(script);
            timer = setInterval(check, 500);
        } else {
            resolve(window.Stripe);
        }
    });
}
