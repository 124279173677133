<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <Container ref="container">
            <pre>{{ payInfo }}</pre>
            <List>
                <ListItem>
                    <div
                        class="icon logo-icon"
                        slot="icon"
                    >
                        <img :src="$globalVar.shopData.logoUrl">
                    </div>
                    <div
                        class="label"
                        slot="label"
                    >
                        <div>{{ $globalVar.shopData.name }}</div>
                        <div class="fs-small tc-g6 lh-1em">
                            {{ $globalVar.shopData.siteUrl }}
                        </div>
                    </div>
                </ListItem>
                <ListItem label="订单金额">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ payInfo.paymentAmount }}
                        </div>
                    </div>
                </ListItem>
                <ListItem
                    v-if="paymentPoundage.paymentPoundage"
                    label="支付手续费"
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ paymentPoundage.paymentPoundage }}
                        </div>
                        <div
                            v-if="paymentPoundage"
                            class="tc-red fs-small"
                        >
                            需额外收取 {{ paymentPoundage.paymentPoundageRate }}% 的订单金额
                        </div>
                    </div>
                </ListItem>
                <ListItem label="总金额">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ payInfo.paymentAmount + (paymentPoundage?paymentPoundage.paymentPoundage:0) | price }}
                        </div>
                    </div>
                </ListItem>
            </List>
        </Container>
        <PageBar>
            <Button
                label="立即支付"
                theme="main"
                @click="onSubmit"
            />
        </PageBar>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { Buyer } from '@/api';

export default {
    name: 'PayResult',
    mixins: [pageMixin],
    data() {
        return {
            payInfo: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Ex.Pay.getOrderPayInfo(to.params)
            .then(res => {
                next(vm => {
                    vm.payInfo = res.data.data;
                });
            })
            .catch(() => next());
    },
    computed: {
        paymentType() {
            return this.$route.params.paymentType;
        },
        action() {
            return this.$route.params.action;
        },
        orderIds() {
            return this.$route.params.orderIds;
        },
        module() {
            return this.$route.params.module;
        },

        paymentPoundage() {
            return this.payInfo.paymentPoundage || {};
        },
    },
    methods: {
        // 外链支付
        gotoHref(href) {
            // return this.done().then(() => {
            // });
            window.location.href = href;
        },
        onSubmit() {
            this.gotoHref(`/rpc/buyer/ex/pay/${this.action}?orderIds=${this.orderIds}&module=${this.module}`);
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.logo-icon {
    width: 0.5rem;
    border-radius: 100em;
    overflow: hidden;
    > img {
        width: 100%;
        height: auto;
    }
}
</style>
