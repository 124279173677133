<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page
        class="bc-w"
        :title="pageData.title"
    >
        <container ref="container">
            <div class="inner">
                <div class="status">
                    <div :class="['icon', success?'tc-green':'tc-second']">
                        <i :class="['f-icon', 'loading-icon', success?'tc-green':'tc-second']">{{ success?'&#xf019;':payErrorStr?'&#xf037;':'&#xf042;' }}</i>
                        <div class="label">
                            {{ success?'支付成功':payErrorStr?'支付失败':'支付中...' }}
                        </div>
                    </div>
                    <div class="order-info">
                        <!--<div class="shop-name">{{ $globalVar.shopData.name }}</div>
                        <div class="shop-name fs-small tc-g6">{{ $globalVar.shopData.siteUrl }}</div>-->

                        <div
                            v-if="success"
                            class="price"
                        >
                            {{ payment.paymentAmount | price }}
                        </div>
                    </div>
                </div>
                <List class="pa-t pa-b bor-t">
                    <ListItem
                        label="付款单号"
                        :extra="paymentSn"
                    />
                </List>

                <div
                    v-if="payErrorStr"
                    class="ma-t ta-l tc-red"
                    v-html="payErrorStr.msg"
                />
                <div
                    v-if="payErrorStr"
                    class="footer ta-c"
                >
                    <Linker
                        class="btn btn-second link bor radius"
                        to="/order/list/0"
                    >
                        继续支付
                    </Linker>
                    <div
                        class="btn btn-main link bor radius ma-l"
                        @click="checkPayment"
                    >
                        已支付
                    </div>
                </div>
                <div
                    v-else
                    class="footer ta-c"
                >
                    <div
                        v-if="payment.nextUrl"
                        class="btn btn-green link bor radius"
                        @click="onComplete"
                    >
                        完成
                    </div>
                </div>
            </div>

            <Widget
                :id="10"
                no-show-complete
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'PayResult',
    mixins: [pageMixin],
    data() {
        return {
            payment: {},
            payErrorStr: null,
        };
    },
    computed: {
        success() {
            return !!this.payment.nextUrl;
        },
        paymentSn() {
            return this.$route.params.paymentSn;
        },
        type() {
            return this.$route.params.type;
        },
    },
    methods: {
        onComplete() {
            if (+this.type === 3) {
                this.$globalVar.routerEdReplace = true;
            }
            this.$router.replace({
                path: this.payment.nextUrl,
            });
        },
        checkPayment() {
            this.$nprogress.start();
            this.$api.Buyer.Ex.Pay.checkPayment({
                module: this.type,
                paymentSn: this.paymentSn,
            })
                .then(json => {
                    const res = json.data;
                    if (res.success) {
                        this.payment = json.data.data;
                        this.payErrorStr = null;
                        return;
                    }
                    this.payErrorStr = {
                        msg:
                            '失败信息: <br>如已成功付款请点击【已支付】更新付款状态<br>如未成功扣款请点击【继续支付】完成订单付款',
                        // '订单付款失败，如已成功付款，请点击【已支付】按钮更新付款状态，如未成功扣款请点击【继续支付】',
                    };
                    /*this.$messageBox
                        .confirm(res.msg, '温馨提示', {
                            cancelBtnText: '已支付',
                            confirmBtnText: '去支付',
                        })
                        .then(() => {
                            this.$router.replace('/order/list/0');
                        })
                        .catch(() => {
                            this.checkPayment();
                        });*/
                })
                .finally(() => {
                    this.$nprogress.done();
                });
        },
    },
    created() {
        this.checkPayment();
    },
};
</script>

<style lang="scss" scoped>
.container {
    > .inner {
        padding: $padding-big ($padding-big * 2) 0 ($padding-big * 2);
        > .status {
            > .icon {
                font-size: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: $gray6;
                > .label {
                    font-size: 0.4rem;
                    margin-top: $margin;
                }
            }

            > .order-info {
                margin: ($margin-big * 3) 0;
                text-align: center;
                font-size: 0.36rem;
                > .price {
                    color: $color-text;
                    font-size: 0.8rem;
                    font-weight: bold;
                    line-height: 1;
                    margin-top: $margin;
                    &:before {
                        /*vertical-align: top;*/
                        margin-right: $margin-small;
                    }
                }
            }
        }

        > .footer {
            margin-bottom: $margin-big * 3;
            > .btn {
                min-width: 40%;
                padding: $padding;
            }
        }
    }
}
</style>
