<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <List>
                <ListItem>
                    <div
                        class="icon logo-icon"
                        slot="icon"
                    >
                        <img :src="$globalVar.shopData.logoUrl">
                    </div>
                    <div
                        class="label"
                        slot="label"
                    >
                        <div>{{ $globalVar.shopData.name }}</div>
                        <div class="fs-small tc-g6 lh-1em">
                            {{ $globalVar.shopData.siteUrl }}
                        </div>
                    </div>
                </ListItem>
                <ListItem label="订单金额">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ payInfo.paymentAmount }}
                        </div>
                    </div>
                </ListItem>
                <ListItem label="支付手续费">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ paymentPoundage.paymentPoundage }}
                        </div>
                        <div
                            v-if="paymentPoundage"
                            class="tc-red fs-small"
                        >
                            需额外收取 {{ paymentPoundage.paymentPoundageRate }}% 的订单金额
                        </div>
                    </div>
                </ListItem>
                <ListItem label="总金额">
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <div class="price">
                            {{ payInfo.paymentAmount + (paymentPoundage?paymentPoundage.paymentPoundage:0) | price }}
                        </div>
                    </div>
                </ListItem>
            </List>

            <div
                v-if="!cardInfoId"
                class="stripe-wrap"
            >
                <List class="list-stripe">
                    <ListItem>
                        <div
                            class="label"
                            slot="label"
                        >
                            <span class="fs-small">信用卡号</span>
                            <div ref="cardNumber" />
                        </div>
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <span class="fs-small">安全码</span>
                            <div ref="cardCvc" />
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            class="label"
                            slot="label"
                        >
                            <span class="fs-small">过期日</span>
                            <div ref="cardExpiry" />
                        </div>
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <span class="fs-small">账单邮编</span>
                            <div ref="postalCode" />
                            <div class="desc fs-small tc-g6">
                                (中国信用卡任意输入6位)
                            </div>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            class="label"
                            slot="label"
                        >
                            <checkbox
                                v-model="isSaveCard"
                                val="1"
                            >
                                保存卡号 下次无需输入
                            </checkbox>
                        </div>
                    </ListItem>

                    <div
                        class="valid-error"
                        v-if="errorMsg"
                    >
                        {{ errorMsg }}
                    </div>
                </List>
            </div>
            <div
                v-else
                class="stripe-wrap"
            >
                <List>
                    <ListItem
                        label="卡类型"
                        :extra="cardData.brand"
                    />
                    <ListItem
                        label="卡号"
                        :extra="cardData.cardNo"
                    />
                    <ListItem
                        label="到期日期"
                        :extra="cardData.expDate"
                    />
                </List>
            </div>
        </container>
        <div class="footer">
            <div
                class="btn btn-main"
                @click="onPlay"
            >
                立即支付
            </div>
        </div>
    </Page>
</template>

<script>
// import Stripe from 'Stripe';
import loadStrip from './loadStripe';
import { Buyer } from '@/api';
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'Stripe',
    mixins: [pageMixin],
    data() {
        return {
            stripe: null,
            cardList: [],
            isSaveCard: true,
            errorMsg: '',
            payInfo: {},
            cardData: {},
        };
    },
    computed: {
        module() {
            return +this.$route.params.module;
        },
        orderIds() {
            return this.$route.params.orderIds;
        },
        cardInfoId() {
            return +this.$route.query.cardInfoId || 0;
        },
        currency() {
            return this.$store.getters['global/currency'];
        },
        paymentPoundage() {
            return this.payInfo.paymentPoundage || {};
        },
    },
    beforeRouteEnter(to, from, next) {
        const { module, orderIds } = to.params;
        Promise.all([
            Buyer.Ex.Pay.getOrderPayInfo({
                module,
                orderIds,
                paymentType: 64,
            }),
            loadStrip(),
        ])
            .then(results => {
                const res = results[0].data.data;
                const Stripe = results[1];
                const stripe = Stripe(res.stripePublishableKey);
                next(vm => {
                    vm.payInfo = res;
                    vm.stripe = stripe;
                });
            })
            .catch(() => next());
    },
    methods: {
        getCardDetails() {
            this.$api.Buyer.Mb.CardInfo.cardInfoDetail({
                cardInfoId: this.cardInfoId,
            }).then(json => {
                const result = json.data;
                this.cardData = result.data;
            });
        },
        createStripe() {
            this.$nprogress.start();
            const elements = this.stripe.elements();
            ['cardNumber', 'cardExpiry', 'cardCvc', 'postalCode'].forEach(item => {
                this.cardList.push(this.registerElements(elements, item));
            });
            this.$nprogress.done();
        },
        registerElements(elements, tag = 'card') {
            const { fontSize } = this.$globalVar.device;
            const placeholderMap = {
                cardNumber: undefined,
                cardExpiry: 'MM/YY',
                cardCvc: '卡背面三位',
                postalCode: 'V7A5E2',
            };
            const elementStyles = {
                base: {
                    color: '#32325d',
                    lineHeight: `20px`,
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: `${fontSize * 0.26}px`,
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };
            const cardElements = elements.create(tag, {
                iconStyle: 'solid',
                style: elementStyles,
                placeholder: placeholderMap[tag],
            });
            cardElements.mount(this.$refs[tag]);

            cardElements.addEventListener('change', event => {
                if (event.elementType === 'cardNumber') {
                    this.errorMsg = event.error ? event.error.message : '';

                    if (event.brand === 'amex') {
                        this.errorMsg = '系统不支持美国运通卡，请使用Visa或Mastercard';
                    }
                }
            });
            return cardElements;
        },
        onPlay() {
            this.$nprogress.start();
            if (!this.cardInfoId) {
                this.stripe
                    .createToken(this.cardList[0])
                    .then(result => {
                        if (result.error) {
                            this.errorMsg = result.error ? result.error.message : '';
                            this.$nprogress.done();
                            return;
                        }
                        const token = result.token;
                        this.$api.Buyer.Ex.Pay.payforStripe({
                            module: this.module,
                            orderIds: this.orderIds,
                            token: token.id,
                            payType: this.isSaveCard ? 1 : 2,
                        })
                            .then(json => {
                                const res = json.data.data;
                                this.gotoResult(res.payment);
                            })
                            .catch(() => {
                                this.$nprogress.done();
                            });
                    })
                    .catch(() => {
                        this.$nprogress.done();
                    });
            } else {
                this.$api.Buyer.Ex.Pay.payforStripe({
                    module: this.module,
                    orderIds: this.orderIds,
                    cardInfoId: this.cardInfoId,
                    payType: 1,
                })
                    .then(json => {
                        const res = json.data.data;
                        this.gotoResult(res.payment);
                        this.$nprogress.done();
                    })
                    .catch(() => {
                        this.$nprogress.done();
                    });
            }
        },
        gotoResult(payment) {
            this.$router.replace(`/pay/payResult/${payment.module}/${payment.paymentSn}`);
            /*const { orderType } = this.payInfo;
            switch (orderType) {
                case 100:
                    // 普通订单
                    this.$router.replace(`/order/list/0`);
                    break;
                case 20:
                    // 拼团订单
                    this.$router.replace(`/goods/groupBuy/${this.orderIds}`);
                    break;
            }*/
        },
    },
    mounted() {
        this.$nextTick(() => (this.cardInfoId ? this.getCardDetails() : this.createStripe()));
    },
};
</script>

<style lang="scss">
.list-stripe {
    > .list-item {
        align-items: flex-start;
        + .list-item {
            border-top: 0;
        }

        > .label {
            flex: 3 3 2%;
        }
        > .extra {
            flex: 2 2 1%;
            text-align: left;
        }
    }
}

.valid-error {
    color: #fa755a;
    padding: $padding;
}

.StripeElement {
    line-height: 0.4rem;
    padding: 0.1rem;
    /*border: 1px solid transparent;*/
    background-color: #fff;
    /*box-shadow: 0 1px 3px 0 #e6ebf1;*/
    transition: box-shadow 150ms ease;
    border: 1px solid $color-border;
    border-radius: 0.05rem;
}

.StripeElement--focus {
    /*box-shadow: 0 1px 3px 0 #cfd7df;*/
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
</style>
<style lang="scss" scoped>
.logo-icon {
    width: 0.5rem;
    border-radius: 100em;
    overflow: hidden;
    > img {
        width: 100%;
        height: auto;
    }
}
</style>
